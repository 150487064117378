import React from "react";
import Image2 from "../../Assets/profile.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function birthcrd() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
  };

  const birth = [
    {
      title: "Birthday",
      para: "EBP-111-Laxmi Bist",
      para1: "Developer-React",
      para2: "Kathmandu,Nepal",
    },
    {
      title: "Birthday",
      para: "EBP-111-Laxmi Bist",
      para1: "Developer-React",
      para2: "Kathmandu,Nepal",
    },
    {
      title: "Birthday",
      para: "EBP-111-Laxmi Bist",
      para1: "Developer-React",
      para2: "Kathmandu,Nepal",
    },
    {
      title: "Birthday",
      para: "EBP-111-Laxmi Bist",
      para1: "Developer-React",
      para2: "Kathmandu,Nepal",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {birth.map(function (birth) {
          return (
            <div>
              <div className="card birth">
                <div className="card-body">
                  <h5>Birthday</h5>
                  <div className="row birrow">
                    <div className="col-3">
                      <div className="card_image">
                        <a href="#">
                          <img src={Image2} />
                        </a>
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="text">
                        <p>EBP-111-Laxmi Bist</p>
                        <p>Developer-React</p>
                        <p>Kathmandu,Nepal</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn sendmsg"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Send Message
                </button>
              </div>
            </div>
          );
        })}
      </Slider>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body wish">
              <strong>Happy birthday</strong> ,It’s been an absolute pleasure to
              work with you side by side. May the next year of your life bring
              all your ambitious plans to reality!
            </div>
            <hr />
            <div className="btn">
              <button>Send</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default birthcrd;
