import React, { useState } from "react";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../Component/Homerouting/ContextApi";

function UTimetraker() {
  const { userData } = useContext(UserContext);
  const [attendance, setAttendance] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      if (userData) {
        const q = query(
          collection(db, "Attendance"),
          where("Name", "==", userData.id)
        );
        onSnapshot(q, (querySnapshot) => {
          const dev = [];
          querySnapshot.forEach((doc) => {
            dev.push({ ...doc.data(), id: doc.id });
          });
          setAttendance(dev);
        });
      }
    };
    fetchData();
  }, [userData]);

  useEffect(() => {
    const getDevelopers = async () => {
      const q = query(collection(db, "developers"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDevelopers(dev);
      });
    };
    getDevelopers();
    const getDepartment = async () => {
      const q = query(collection(db, "department"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDepartment(dev);
      });
    };
    getDepartment();
  }, []);
  return (
    <>
      <div style={{ marginLeft: "16rem" }} className="container">
        <MaterialTable
          columns={[
            { title: "ID", field: "id" },
            {
              title: "Name",
              field: "Name",
              render: (rowData) =>
                developers.find((item) => item.id === rowData.Name)?.name,
            },
            {
              title: "Department",
              field: "department",
              render: (rowData) =>
                department.find(
                  (dep) =>
                    dep.id ===
                    developers.find((item) => item.id === rowData.Name)
                      ?.department
                )?.department,
            },
            {
              title: "Date",
              field: "Date",
              render: (data) => {
                if (data.CheckIn !== "" && data.CheckOut !== "") {
                  return moment(data.CheckIn).format("MMM Do YYYY");
                }
              },
            },
            {
              title: "Total Hour",
              field: "TotalHour",
              render: (data) => {
                if (data.CheckIn !== "" && data.CheckOut !== "") {
                  let startTime = new Date(data.CheckIn).getTime();
                  let endTime = new Date(data.CheckOut).getTime();

                  let time = `${(
                    ((endTime - startTime) / 3600000) *
                    60
                  ).toFixed(2)}`;
                  var Hours = Math.floor(time / 60);
                  var minutes = time % 60;
                  return Hours + " hour and " + minutes + " minutes.";
                } else {
                  return null;
                }
              },
            },
          ]}
          data={attendance}
          title="Time Tracker"
        />
      </div>
    </>
  );
}

export default UTimetraker;
