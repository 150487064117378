import React from "react";
import DashboardPage from "./DashboardPage";
import Stopewatch from "./Stopewatch";

function UserDashboard() {
  return (
    <div className="container">
      <DashboardPage />
      <Stopewatch />
    </div>
  );
}

export default UserDashboard;
