import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <div className="sidebar">
        <div className="navlist">
          <ul className="item">
            <li>
              <Link to="/dashboard">
                <div>
                  <i className="fa-solid fa-house-chimney"></i>
                </div>
                Dashboard
              </Link>
            </li>

            <li>
              <Link to="/attendans">
                <div>
                  <i class="fa-solid fa-calendar-check"></i>
                </div>
                Attendance
              </Link>
            </li>
            {/* <li>
              <Link to="/absentees">
                <div>
                  <i class="fa-solid fa-calendar"></i>
                </div>
                Absentees
              </Link>
            </li> */}
            <li>
              <Link to="/leavereq">
                <div>
                  <i class="fa-solid fa-calendar-days"></i>
                </div>
                Leave <br />
                Request
              </Link>
            </li>
            <li>
              <Link to="/timetraker">
                <div>
                  <i class="fa-solid fa-clock"></i>
                </div>
                Time
                <br /> Tracker
              </Link>
            </li>
            <li>
              <Link to="/department">
                <div>
                  <i class="fa-solid fa-building"></i>
                </div>
                Department
              </Link>
            </li>
            <li>
              <Link to="/employes">
                <div>
                  <i class="fa-solid fa-people-group"></i>
                </div>
                Employes
              </Link>
            </li>
            {/* <li>
              <Link to="newhire">
                <div>
                  <i class="fa-solid fa-user-check"></i>
                </div>{" "}
                New hires
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
