import React,{useState} from "react";
import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import Image3 from "../../../src/Assets/profile.jpg";
import Newhirflt from "../../Pages/Adminpages/Filterlist/Newhirflt"

function Newhire() {
  return (
    <>
    <Newhirflt/>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header att-tit">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3">
                  <label for="exampleInputEmail1 " class="form-label chklab">
                    Check-In
                  </label>
                  <input
                    type="email"
                    class="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label chklab">
                    Check-Out
                  </label>
                  <input
                    type="password"
                    class="form-control check"
                    id="exampleInputPassword1"
                  />
                </div>
              </form>
            </div>
            <hr />
            <div class="attsavchg">
              <button type="button">Save changes</button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableContainer">
        {" "}
        <MaterialTable
          columns={[
            { title: "ID", field: "id" },
            {
              title: "Photo",
              field: "photo",
              render: (data) => (
                <img
                  src={data.photo}
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              ),
            },
            { title: "Name", field: "name" },
            { title: "Department", field: "deprt" },
            { title: "Start", field: "str" },
            ]}
          data={[
            {
              id: "EP1",
              photo: Image3,
              name: "Mehmet",
              deprt: "Baran",
              str: "2056/10/17",
            },
            {
                id: "EP1",
                photo: Image3,
                name: "Mehmet",
                deprt: "Baran",
                str: "2056/10/17",
              },
              {
                id: "EP1",
                photo: Image3,
                name: "Mehmet",
                deprt: "Baran",
                str: "2056/10/17",
              },
              {
                id: "EP1",
                photo: Image3,
                name: "Mehmet",
                deprt: "Baran",
                str: "2056/10/17",
              },
              {
                id: "EP1",
                photo: Image3,
                name: "Mehmet",
                deprt: "Baran",
                str: "2056/10/17",
              },
              {
                id: "EP1",
                photo: Image3,
                name: "Mehmet",
                deprt: "Baran",
                str: "2056/10/17",
              },
             ]}

          title="New Hire employs"
        />
      </div>
    </>
  );
}

export default Newhire;
