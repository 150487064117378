import React from "react";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "./ContextApi";

const AdminRouting = () => {
  const { userRole } = useContext(UserContext);
  if (userRole) {
    return <Outlet />;
  }
  return <Navigate to="/" />;
};

export default AdminRouting;
