import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { UserContext } from "../../Component/Homerouting/ContextApi";

const DashboardPage = () => {
  const { leaveData } = useContext(UserContext);
  const [data, setState] = useState([
    {
      title: "Annual Leave",
      text1: "Avaliable : 20",
      text2: "Booked : 0",
    },

    {
      title: "Public Holiday",
      text1: "Avaliable : 0",
      text2: "Booked : 0",
    },

    {
      title: "Sick Leave",
      text1: "Avaliable : 10",
      text2: "Booked : 0",
    },
  ]);

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(new Date(date2) - new Date(date1));
    const diffs = diffInMs / (1000 * 60 * 60 * 24);
    return diffs;
  }
  useEffect(() => {
    function Date() {
      if (leaveData.length > 0) {
        let sum1 = 0;
        let sum2 = 0;
        for (let i = 0; i < leaveData.length; i++) {
          const date1 = leaveData[i].from;
          const date2 = leaveData[i].to;
          var diff = getDifferenceInDays(date1, date2);
          if (
            leaveData[i].typeOfLeave === "Annual Leave" &&
            leaveData[i].status === "Accepted"
          ) {
            sum1 += diff;
          } else if (
            leaveData[i].typeOfLeave === "Sick Leave" &&
            leaveData[i].status === "Accepted"
          ) {
            sum2 += diff;
          }
        }

        setState([
          {
            title: "Annual Leave",
            text1: "Avaliable : 20",
            text2: `Booked : ${sum1}`,
          },

          {
            title: "Public Holiday",
            text1: "Avaliable : 0",
            text2: "Booked : 0",
          },

          {
            title: "Sick Leave",
            text1: "Avaliable : 10",
            text2: `Booked : ${sum2}`,
          },
        ]);
      }
    }
    Date();
  }, []);

  return (
    <div style={{ marginLeft: "4rem" }} className="container">
      <h1 style={{ color: "#dc3545", marginLeft: "5px" }}>DashBoard</h1>
      <div className="row horizen">
        {data.map((val, ids) => {
          return (
            <div className="col-md-4">
              <div className="card leavecrd">
                <div className="card-bdy " style={{ textAlign: " center" }}>
                  <h5>{val.title}</h5>
                  <div className="mb-3">
                    <i class="fa-solid fa-calendar-day"></i>
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <p
                      style={{
                        backgroundColor: "#198754",
                        color: "white",
                        borderRadius: "5px",
                        padding: "3px",
                        fontWeight: "700",
                      }}
                    >
                      {val.text1}
                    </p>
                    <p
                      style={{
                        backgroundColor: "#dc3545",
                        color: "white",
                        borderRadius: "5px",
                        padding: "3px",
                        fontWeight: "700",
                      }}
                    >
                      {val.text2}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardPage;
