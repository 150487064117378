import React from "react";
import Birthcrd from "../../Component/Birthday/Birthcrd";
import Update from "../../Pages/Adminpages/Update"



function Dashboard() {
  return (
    <div >
      <Birthcrd />
      <Update />
    </div>
  );
}

export default Dashboard;
