import React from "react";
import { useStopwatch } from "react-timer-hook";

function Stopewatch() {
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  return (
    <div
      style={{ marginLeft: "4rem", marginTop: "5rem" }}
      className="container"
    >
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "60px", color: "White" }}>
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
        {isRunning ? (
          <button
            style={{ marginRight: "1rem" }}
            className="btn btn-danger btn-lg"
            onClick={start}
            disabled
          >
            Start
          </button>
        ) : (
          <button
            style={{ marginRight: "1rem" }}
            className="btn btn-danger btn-lg"
            onClick={start}
          >
            Start
          </button>
        )}
        {!isRunning ? (
          <button
            style={{ margin: "1rem 1rem" }}
            className="btn btn-danger btn-lg"
            onClick={pause}
            disabled
          >
            Pause
          </button>
        ) : (
          <button
            style={{ margin: "1rem 1rem" }}
            className="btn btn-danger btn-lg"
            onClick={pause}
          >
            Pause
          </button>
        )}
        {isRunning ? (
          <button
            style={{ marginLeft: "1rem" }}
            className="btn btn-danger btn-lg"
            onClick={reset}
            disabled
          >
            Reset
          </button>
        ) : (
          <button
            style={{ marginLeft: "1rem" }}
            className="btn btn-danger btn-lg"
            onClick={reset}
          >
            Reset
          </button>
        )}
      </div>
    </div>
  );
}

export default Stopewatch;
