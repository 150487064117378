import React,{useState} from "react";
import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import Image4 from "../../../src/Assets/profile.jpg";
import Absenflt from "../../Pages/Adminpages/Filterlist/Absenflt";

function Absentees() {
  return (
    <>
    <Absenflt />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header att-tit">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">......</div>
            <hr />
          </div>
        </div>
      </div>

      <div className="tableContainer">
        {" "}
        <MaterialTable
          columns={[
            { title: "ID", field: "id" },
            {
              title: "Photo",
              field: "photo",
              render: (data) => (
                <img
                  src={data.photo}
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              ),
            },
            { title: "Name", field: "name" },
            { title: "Department", field: "deprt" },
          ]}
          data={[
            {
              id: "EP12",
              photo: Image4,
              name: "Mehmet",
              deprt: "Baran",
            },
            {
              id: "EP12",
              photo: Image4,
              name: "Mehmet",
              deprt: "Baran",
            },
            {
              id: "EP12",
              photo: Image4,
              name: "Mehmet",
              deprt: "Baran",
            },
            {
              id: "EP12",
              photo: Image4,
              name: "Mehmet",
              deprt: "Baran",
            },
            {
              id: "EP12",
              photo: Image4,
              name: "Mehmet",
              deprt: "Baran",
            },
          ]}
          title=""
          actions={[
            {
              icon: () => (
                <button
                  type="button"
                  class="btn res-rej"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Reason
                </button>
              ),
              onClick: (event, rowData) => {},
            },
          ]}

          options={{
            actionsColumnIndex: -1,
          }}

          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} of {count}",
            },
            toolbar: {
              nRowsSelected: "{0} row(s) selected",
            },
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage: "No records to display",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default Absentees;
