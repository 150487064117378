import logo from "./logo.svg";
import "./App.css";
// import Aemptable from './Pages/Aemptable';
// import Employlst from './Component/Aemploylist/Employlst';
// import Aleavetable from './Pages/Aleavetable'
// import Addempfom from './Pages/Addempfom'
// import Attendns from './Pages/Adminpages/Attendns'
// import Attendns from'../src/Pages/Adminpages/Attendns';
// import Leavereq from'../src/Pages/Adminpages/Leavereq';
//  import Empdetails from'../src/Pages/Adminpages/Employes/Empdetails';
// import Addemploy from'../src/Pages/Adminpages/Employes/Addemploy';

// import Adminrouting from "./Component/Homerouting/Adminrouting";
import Navbar from "./Component/Navigation/Navbar";
import Sidebar from "./Component/Sidebar/Sidebar";
import AllRoutes from "./Component/Homerouting";
import { useState } from "react";

// import UserRouting from "./Component/Homerouting/UserRoute";
// import Leaveform from './Pages/Userpages/Leaveform'
// import Leavetrkpage from './Pages/Userpages/Leavetrkpage'
// import UserAttendns from './Pages/Userpages/UserAttendns'

// import Stopewatch from "./Pages/Userpages/Stopewatch";

const App = () => {
  return (
    <>
      <AllRoutes />
    </>
  );
};

export default App;
