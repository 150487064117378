import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import Empflt from "../Filterlist/Empflt";
import { db } from "../../../firebase.js";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useSnackbar } from "notistack";

function Employes() {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedDeveloperId, setSelectedDeveloperId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatePassword, setUpdatePassword] = useState("");
  const [updateGender, setUpdateGender] = useState("");
  const [updatePhone, setUpdatePhone] = useState(0);
  const [updatePosition, setUpdatePosition] = useState("");
  const [updateAddress, setUpdateAddress] = useState("");
  const [updateDob, setUpdateDob] = useState("");
  const [updateStartDate, setUpdateStartDate] = useState("");
  const [updateSalary, setUpdateSalary] = useState("");

  const [updateUserRole, setUpdateUserRole] = useState("user");
  const [newAddress, setNewAddress] = useState("");
  const [newDob, setNewDob] = useState("");
  const [newStartDate, setNewStartDate] = useState("");
  const [newSalary, setNewSalary] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newGender, setNewGender] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [developers, setDevelopers] = useState([]);
  const usersCollectionRef = collection(db, "developers");

  const [department, setDepartment] = useState([]);
  const [newDepartmentName, setNewDepartmentName] = useState([]);
  const [updateDepartmentName, setUpdateDepartmentName] = useState([]);

  const createDeveloper = async () => {
    if (newName === "") {
      return enqueueSnackbar("Name cannot be empty", { variant: "error" });
    } else if (newEmail === "") {
      return enqueueSnackbar("Email cannot be empty", { variant: "error" });
    } else if (newAddress === "") {
      return enqueueSnackbar("Address cannot be empty", { variant: "error" });
    } else if (newDob === "") {
      return enqueueSnackbar("Date Of Birth cannot be empty", {
        variant: "error",
      });
    } else if (newStartDate === "") {
      return enqueueSnackbar("StartDate cannot be empty", { variant: "error" });
    } else if (newSalary === "") {
      return enqueueSnackbar("Salary cannot be empty", { variant: "error" });
    } else if (newPassword === "") {
      return enqueueSnackbar("Password cannot be empty", { variant: "error" });
    } else if (newGender === "") {
      return enqueueSnackbar("Gender cannot be empty", { variant: "error" });
    } else if (newPhone === "") {
      return enqueueSnackbar("Phone cannot be empty", { variant: "error" });
    } else if (newPosition === "") {
      return enqueueSnackbar("position cannot be empty", { variant: "error" });
    } else if (newDepartmentName === "") {
      return enqueueSnackbar("Department Name cannot be empty", {
        variant: "error",
      });
    } else {
      await addDoc(usersCollectionRef, {
        name: newName,
        email: newEmail,
        password: newPassword,
        gender: newGender,
        phone: newPhone,
        position: newPosition,
        userRole: "User",
        department: newDepartmentName,
        address: newAddress,
        dob: newDob,
        startDate: newStartDate,
        salary: newSalary,
        id: selectedDeveloperId,
      })
        .then(() => {
          setNewName("");
          setNewEmail("");
          setNewGender("");
          setNewPhone("");
          setNewPassword("");
          setNewPosition("");
          setNewDepartmentName("");
          setNewAddress("");
          setNewDob("");
          setNewStartDate("");
          setNewSalary("");
        })
        .then(() =>
          enqueueSnackbar("Employee Added Successfully", { variant: "success" })
        );
    }
  };

  const updateDeveloper = async () => {
    if (updateName === "") {
      return enqueueSnackbar("Name cannot be empty", { variant: "error" });
    } else if (updateEmail === "") {
      return enqueueSnackbar("Email cannot be empty", { variant: "error" });
    } else if (updatePassword === "") {
      return enqueueSnackbar("Password cannot be empty", { variant: "error" });
    } else if (updateAddress === "") {
      return enqueueSnackbar("Address cannot be empty", { variant: "error" });
    } else if (updateDob === "") {
      return enqueueSnackbar("Date Of Birth cannot be empty", {
        variant: "error",
      });
    } else if (updateStartDate === "") {
      return enqueueSnackbar("StartDate cannot be empty", { variant: "error" });
    } else if (updateSalary === "") {
      return enqueueSnackbar("Salary cannot be empty", { variant: "error" });
    } else if (updateGender === "") {
      return enqueueSnackbar("Gender cannot be empty", { variant: "error" });
    } else if (updatePhone === "") {
      return enqueueSnackbar("Phone cannot be empty", { variant: "error" });
    } else if (updatePosition === "") {
      return enqueueSnackbar("position cannot be empty", { variant: "error" });
    } else if (updateDepartmentName === "") {
      return enqueueSnackbar("Department Name cannot be empty", {
        variant: "error",
      });
    } else {
      const developerDoc = doc(db, "developers", selectedDeveloperId);
      await updateDoc(developerDoc, {
        name: updateName,
        email: updateEmail,
        password: updatePassword,
        gender: updateGender,
        phone: updatePhone,
        position: updatePosition,
        userRole: updateUserRole,
        department: updateDepartmentName,
        address: updateAddress,
        dob: updateDob,
        startDate: updateStartDate,
        salary: updateSalary,
      }).then(() =>
        enqueueSnackbar("Employee Updated Successfully", { variant: "success" })
      );
    }
  };

  const deleteDeveloper = async () => {
    const developerDoc = doc(db, "developers", selectedDeveloperId);
    await deleteDoc(developerDoc).then(() =>
      enqueueSnackbar("Employee Deleted Successfully", { variant: "error" })
    );
  };

  useEffect(() => {
    const getDepartment = async () => {
      const q = query(collection(db, "department"));

      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDepartment(dev);
      });
    };
    getDepartment();

    const getDevelopers = async () => {
      const q = query(collection(db, "developers"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDevelopers(dev);
      });
    };
    getDevelopers();
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <Empflt />
      {/*modal for edit form */}
      <div
        className="modal fade"
        id="edit-emp-Modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header add-emp-tit">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit employes data
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Name<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdateName(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateName}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Address<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdateAddress(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateAddress}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Date Of Birth<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdateDob(event.target.value);
                    }}
                    type="date"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateDob}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    StartDate<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdateStartDate(event.target.value);
                    }}
                    type="date"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateStartDate}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Salary<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdateSalary(event.target.value);
                    }}
                    type="number"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateSalary}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Phone number<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdatePhone(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputPassword1"
                    value={updatePhone}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Email<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdateEmail(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputPassword1"
                    value={updateEmail}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Password<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setUpdatePassword(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputPassword1"
                    value={updatePassword}
                  />
                </div>

                <label
                  for="exampleInputPassword1"
                  className="form-label chklab"
                >
                  Gender<span>*</span>
                </label>
                <select
                  onChange={(event) => {
                    setUpdateGender(event.target.value);
                  }}
                  className="form-select datehis"
                  aria-label="Default select example"
                  value={updateGender}
                >
                  <option selected value="">
                    Choose Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Position<span>*</span>
                  </label>
                  <select
                    onChange={(event) => {
                      setUpdatePosition(event.target.value);
                    }}
                    className="form-select datehis"
                    aria-label="Default select example"
                    value={updatePosition}
                  >
                    <option selected value="">
                      Choose Position
                    </option>
                    <option value="HR">HR</option>
                    <option value="Senior developer">Senior developer</option>
                    <option value="Junior developer">Junior developer</option>
                  </select>
                </div>
                <label>
                  Department Name<span>*</span>{" "}
                </label>
                <select
                  onChange={(event) => {
                    setUpdateDepartmentName(event.target.value);
                  }}
                  class="form-select datehis"
                  aria-label="Default select example"
                  value={updateDepartmentName}
                >
                  <option selected value="">
                    Choose Department Name
                  </option>
                  {department.map((department) => {
                    return (
                      <option value={department.id}>
                        {department.department}
                      </option>
                    );
                  })}
                </select>
                <div className="form-check">
                  <input
                    onChange={(event) => {
                      event.target.checked
                        ? setUpdateUserRole("Admin")
                        : setUpdateUserRole("User");
                    }}
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckIndeterminate"
                    value="admin"
                    checked={updateUserRole === "Admin"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckIndeterminate"
                  >
                    Admin Privileges
                  </label>
                </div>
              </form>
            </div>
            <hr />
            <div className="attsavchg">
              <button
                data-bs-dismiss="modal"
                onClick={updateDeveloper}
                type="button"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to delete employes */}
      <div
        className="modal fade "
        id="delete-emp-Modal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div tabIndex={-1}>
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>Do you want do delete?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  data-bs-dismiss="modal"
                  onClick={deleteDeveloper}
                  type="button"
                  className="btn btn-danger"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for employes add form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mdlnewemp">
          <div className="modal-content ">
            <div className="modal-header emphed">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Employes
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Name<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewName(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={newName}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Address<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewAddress(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={newAddress}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Date Of Birth<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewDob(event.target.value);
                    }}
                    type="date"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={newDob}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    StartDate<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewStartDate(event.target.value);
                    }}
                    type="date"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={newStartDate}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Salary<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewSalary(event.target.value);
                    }}
                    type="number"
                    className="form-control check"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={newSalary}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Phone number<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewPhone(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputPassword1"
                    value={newPhone}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Email<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewEmail(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputPassword1"
                    value={newEmail}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Create Password<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewPassword(event.target.value);
                    }}
                    type="text"
                    className="form-control check"
                    id="exampleInputPassword1"
                    value={newPassword}
                  />
                </div>
                <label
                  for="exampleInputPassword1"
                  className="form-label chklab"
                >
                  Gender<span>*</span>
                </label>
                <select
                  required
                  onChange={(event) => {
                    setNewGender(event.target.value);
                  }}
                  className="form-select datehis"
                  aria-label="Default select example"
                  value={newGender}
                >
                  <option selected value="">
                    Choose Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label chklab"
                  >
                    Position<span>*</span>
                  </label>
                  <select
                    required
                    onChange={(event) => {
                      setNewPosition(event.target.value);
                    }}
                    className="form-select datehis"
                    aria-label="Default select example"
                    value={newPosition}
                  >
                    <option selected value="">
                      Choose Position
                    </option>
                    <option value="HR">HR</option>
                    <option value="Senior developer">Senior developer</option>
                    <option value="Junior Developer">Junior developer</option>
                  </select>
                </div>
                <label>
                  Department Name<span>*</span>{" "}
                </label>
                <select
                  onChange={(event) => {
                    setNewDepartmentName(event.target.value);
                  }}
                  class="form-select datehis"
                  aria-label="Default select example"
                  value={newDepartmentName}
                >
                  <option selected value="">
                    Choose Department Head
                  </option>
                  {department.map((department) => {
                    return (
                      <option value={department.id}>
                        {department.department}
                      </option>
                    );
                  })}
                </select>
              </form>
              <hr />
              <div className="empbtn">
                <button
                  onClick={createDeveloper}
                  data-bs-dismiss="modal"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MaterialTable
        columns={[
          // { title: "ID", field: "id" },
          { title: "Name", field: "name" },
          { title: "Position", field: "position" },
          { title: "Phone", field: "phone" },
          { title: "Email", field: "email" },
          { title: "UserRole", field: "userRole" },
          {
            title: "Department",
            field: "department",
            render: (rowData) =>
              department.find((item) => item.id === rowData.department)
                ?.department,
          },
        ]}
        data={developers}
        title=" "
        actions={[
          {
            icon: () => (
              <button type="button" className="btn btn-success">
                <i className="fa-solid fa-eye"></i>
              </button>
            ),
            tooltip: "view detail",
            onClick: (event, rowData) => {
              navigate(`/employes/empdetails/${rowData.id}`);
            },
          },
          {
            icon: () => (
              <button
                type="button"
                className="btn attbtn"
                data-bs-toggle="modal"
                data-bs-target="#edit-emp-Modal"
              >
                <i className="fa-solid fa-pencil"></i>
              </button>
            ),
            tooltip: "Edit data",
            onClick: (event, rowData) => {
              setSelectedDeveloperId(rowData.id);
              setUpdateName(rowData.name);
              setUpdateGender(rowData.gender);
              setUpdateEmail(rowData.email);
              setUpdatePassword(rowData.password);
              setUpdatePhone(rowData.phone);
              setUpdatePosition(rowData.position);
              setUpdateUserRole(rowData.userRole);
              setUpdateDepartmentName(rowData.department);
              setUpdateAddress(rowData.address);
              setUpdateDob(rowData.dob);
              setUpdateStartDate(rowData.startDate);
              setUpdateSalary(rowData.salary);
            },
          },
          {
            icon: () => (
              <button
                type="button"
                className="btn viewbtn"
                data-bs-toggle="modal"
                data-bs-target="#delete-emp-Modal"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
            tooltip: "Edit data",
            onClick: (event, rowData) => {
              setSelectedDeveloperId(rowData.id);
            },
          },

          {
            icon: () => (
              <div>
                {/* Button trigger modal */}
                <button
                  className="newempbtn"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Add Employes
                </button>
              </div>
            ),
            isFreeAction: true,
            onClick: (event, rowData) => {},
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          toolbarButtonAlignment: "left",
        }}
        // localization={{
        //   pagination: {
        //     labelDisplayedRows: "{from}-{to} of {count}",
        //   },
        //   toolbar: {
        //     nRowsSelected: "{0} row(s) selected",
        //   },
        //   header: {
        //     actions: "",
        //   },
        //   body: {
        //     emptyDataSourceMessage: "No records to display",
        //     filterRow: {
        //       filterTooltip: "Filter",
        //     },
        //   },
        // }}
      />
    </div>
  );
}

export default Employes;
