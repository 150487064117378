import React, { useState } from "react";

function Depflt() {
  const [showTable, setShowTable] = useState(false);
  const handeltoggle = () => {
    setShowTable(!showTable);
  };
  return (
    <>
      <div className="container ">
      <div className="both-btn">
        <button class="filtercls" onClick={handeltoggle}>
          {showTable ? "Hide Filter" : "Show Filter"}
        </button>
        </div>
        {showTable === true ? (
          <form>
            <div class="form-group nmg">
              <label class=" inpnmg" for="exampleInputEmail1">
              Department Head
              </label>
              <select
              class="form-select datehis"
              aria-label="Default select example"
            >
              <option selected>Department Head</option>
              <option value="1">HR</option>
              <option value="2"></option>
              <option value="3"></option>
            </select>
            </div>

            <div class="form-group nmg">
            <label class=" inpnmg" for="exampleInputEmail1">
            Department
            </label>
            <select
              class="form-select datehis"
              aria-label="Default select example"
            >
              <option selected>Department</option>
              <option value="1">React</option>
              <option value="2">Laravel</option>
              <option value="3">Flutter</option>
            </select>
            </div>
            <div class="att-fil-btn">
              <button type="button">Filter</button>
            </div>
          </form>
        ) : null}
      </div>
    </>
  );
}

export default Depflt;
