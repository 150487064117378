import { Routes, Route } from "react-router-dom";
import Loginpage from "../../Pages/Loginpage";
import PrivateRouting from "./PrivateRouting";
import Leaveform from "../../Pages/Userpages/Leaveform";
import Leavepage from "../../Pages/Userpages/Leavepage";
import UserDashboard from "../../Pages/Userpages/UserDashboard";
import Dashboard from "../../Pages/Adminpages/Dashboard";
import Attendans from "../../Pages/Adminpages/Attendns";
import Leavereq from "../../Pages/Adminpages/Leavereq";
import Employes from "../../Pages/Adminpages/Employes/Employes";
import Empdetails from "../../Pages/Adminpages/Employes/Empdetails";
import Department from "../../Pages/Adminpages/Department";
import Newhire from "../../Pages/Adminpages/Newhire";
import Timetraker from "../../Pages/Adminpages/Timetraker";
import Absentees from "../../Pages/Adminpages/Absentees";
import SharedLayout from "../SharedLayout.js";
import AdminRouting from "./AdminRouting";
import SharedLayout2 from "../SharedLayout2";
import Utimetraker from "../../Pages/Userpages/Utimetraker";
import UserAttndns from "../../Pages/Userpages/UserAttendns";

const AllRoutes = () => {
  return (
    <Routes>
      <Route index element={<Loginpage></Loginpage>} />
      <Route element={<PrivateRouting />}>
        <Route path="/" element={<SharedLayout2 />}>
          <Route path="/leavePage" element={<Leavepage />} />
          <Route path="/leaveForm" element={<Leaveform />} />
          <Route path="/timeTracker" element={<Utimetraker />} />
          <Route path="/attendance" element={<UserAttndns />} />
          <Route path="/userDashboard" element={<UserDashboard />} />
        </Route>
      </Route>

      <Route element={<AdminRouting />}>
        <Route path="/" element={<SharedLayout />}>
          <Route path="/attendans" element={<Attendans />} />
          <Route path="/leavereq" element={<Leavereq />} />
          <Route path="/employes" element={<Employes />} />
          <Route path="/employes/empdetails/:id" element={<Empdetails />} />
          <Route path="/department" element={<Department />} />
          <Route path="/newhire" element={<Newhire />} />
          <Route path="/timetraker" element={<Timetraker />} />
          <Route path="/absentees" element={<Absentees />} />
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AllRoutes;
