import React from "react";
import MaterialTable from "material-table";
import moment from "moment/moment";
import { collection, onSnapshot, query, where } from "firebase/firestore";

import { useState } from "react";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../Component/Homerouting/ContextApi";

function UserAttendans() {
  const { userData } = useContext(UserContext);
  const [developers, setDevelopers] = useState([]);
  const [department, setDepartment] = useState([]);

  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      if (userData) {
        const q = query(
          collection(db, "Attendance"),
          where("Name", "==", userData.id)
        );
        onSnapshot(q, (querySnapshot) => {
          const dev = [];
          querySnapshot.forEach((doc) => {
            dev.push({ ...doc.data(), id: doc.id });
          });
          setAttendance(dev);
        });
      }
    };
    fetchData();
  }, [userData]);

  useEffect(() => {
    const getDevelopers = async () => {
      const q = query(collection(db, "developers"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDevelopers(dev);
      });
    };
    getDevelopers();

    const getDepartment = async () => {
      const q = query(collection(db, "department"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDepartment(dev);
      });
    };
    getDepartment();
  }, []);

  function getDifferenceInDays(date1, date2) {
    let startTime = new Date(date1).getTime();
    let endTime = new Date(date2).getTime();
    let time = `${(((endTime - startTime) / 3600000) * 60).toFixed(2)}`;
    var Hours = Math.floor(time / 60);
    var minutes = time % 60;
    return Hours + " hour and " + minutes + " minutes.";
  }

  return (
    <>
      <div style={{ marginLeft: "16rem" }} className="container">
        {" "}
        <MaterialTable
          columns={[
            { title: "ID", field: "id" },

            {
              title: "Name",
              field: "Name",
              render: (rowData) =>
                developers.find((item) => item.id === rowData.Name)?.name,
            },
            {
              title: "Department",
              field: "department",
              render: (rowData) =>
                department.find(
                  (dep) =>
                    dep.id ===
                    developers.find((item) => item.id === rowData.Name)
                      ?.department
                )?.department,
            },
            {
              title: "Check-in",
              field: "CheckIn",
              render: (data) => {
                if (data.CheckIn !== "") {
                  return moment(data.CheckIn).format("MMM Do YYYY, h:mm A");
                } else {
                  return null;
                }
              },
            },
            {
              title: "Check-out",
              field: "CheckOut",
              render: (data) => {
                if (data.CheckOut !== "") {
                  return moment(data.CheckOut).format("MMM Do YYYY, h:mm A");
                } else {
                  return null;
                }
              },
            },
            {
              title: "Total Hour",
              field: "TotalHour",
              render: (data) =>
                getDifferenceInDays(data.CheckIn, data.CheckOut),
            },
          ]}
          data={attendance}
          title="User Attendance"
          // localization={{
          //   pagination: {
          //     labelDisplayedRows: "{from}-{to} of {count}",
          //   },
          //   toolbar: {
          //     nRowsSelected: "{0} row(s) selected",
          //   },
          //   header: {
          //     actions: "",
          //   },
          //   body: {
          //     emptyDataSourceMessage: "No records to display",
          //     filterRow: {
          //       filterTooltip: "Filter",
          //     },
          //   },
          // }}
        />
      </div>
    </>
  );
}

export default UserAttendans;
