import React, { useState } from "react";

function Attdnsflt() {
  const [showTable, setShowTable] = useState(false);
  const handeltoggle = () => {
    setShowTable(!showTable);
  };
  return (
    <>
      <div className="container">
        <button class="filtercls" onClick={handeltoggle}>
          {showTable ? "Hide Filter" : "Show Filter"}
        </button>
        {showTable === true ? (
          <form>
            <div class="form-group nmg">
              <label for="exampleInputEmail1">
                Id<span>*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Id"
              />
            </div>
            <div class="form-group nmg">
              <label for="exampleInputEmail1">
                Name<span>*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Name"
              />
            </div>
            <label class=" inp-dep" for="exampleInputEmail1">
                Department<span>*</span>
              </label>
            <select
              class="form-select datehis"
              aria-label="Default select example"
            >
              <option selected>Department</option>
              <option value="1">React</option>
              <option value="2">Laravel</option>
              <option value="3">Flutter</option>
            </select>
            <div class="form-group nmg">
              <label for="exampleInputPassword1">
                From<span>*</span>
              </label>
              <input
                type="date"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Department"
              />
            </div>
            <div class="form-group nmg">
              <label  for="exampleInputPassword1">
                TO<span>*</span>
              </label>
              <input
                type="date"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Department"
              />
            </div>
            <div class="form-group nmg">
              <label for="exampleInputPassword1">
                Total Hour<span>*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Total Hour"
              />
            </div>
            <div  class="att-fil-btn">
            <button type="button">Filter</button>
            </div>
          </form>
        ) : null}
        
      </div>
    </>
  );
}

export default Attdnsflt;
