import React from "react";
import MaterialTable from "material-table";
import Depflt from "./Filterlist/Depflt";
import { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useSnackbar } from "notistack";

function Department() {
  const { enqueueSnackbar } = useSnackbar();
  const [developers, setDevelopers] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateDepartment, setUpdateDepartment] = useState("");

  const [newName, setNewName] = useState("");
  const [newDepartment, setNewDepartment] = useState("");
  const [department, setDepartment] = useState([]);
  const usersCollectionRef = collection(db, "department");

  const createDepartment = async () => {
    if (newName === "") {
      return enqueueSnackbar("Name cannot be empty", { variant: "error" });
    } else if (newDepartment === "") {
      return enqueueSnackbar("Email cannot be empty", { variant: "error" });
    } else {
      await addDoc(usersCollectionRef, {
        name: newName,
        department: newDepartment,
      })
        .then(() => {
          setNewName("");
          setNewDepartment("");
        })
        .then(() =>
          enqueueSnackbar("Employee Added Successfully", { variant: "success" })
        );
    }
  };

  const updateDepartments = async () => {
    if (updateName === "") {
      return enqueueSnackbar("Name cannot be empty", { variant: "error" });
    } else if (updateDepartment === "") {
      return enqueueSnackbar("Email cannot be empty", { variant: "error" });
    } else {
      const departmentDoc = doc(db, "department", selectedDepartmentId);
      await updateDoc(departmentDoc, {
        name: updateName,
        department: updateDepartment,
      }).then(() =>
        enqueueSnackbar("Department Updated Successfully", {
          variant: "success",
        })
      );
    }
  };

  const deleteDepartment = async () => {
    const departmentDoc = doc(db, "department", selectedDepartmentId);
    await deleteDoc(departmentDoc).then(() =>
      enqueueSnackbar("Employee Deleted Successfully", { variant: "error" })
    );
  };

  useEffect(() => {
    const getDepartment = async () => {
      const q = query(collection(db, "department"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDepartment(dev);
      });
    };
    getDepartment();

    const getDevelopers = async () => {
      const q = query(collection(db, "developers"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDevelopers(dev);
      });
    };
    getDevelopers();
  }, []);

  return (
    <>
      <Depflt />
      {/*modal for edit department */}

      <div
        className="modal fade"
        id="edit-dep-Modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content mdl-edt-dep">
            <div className="modal-header dep-edt-head">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Department
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body mdl-edt-bdy">
              <form class="fom-edt-stl">
                <label>
                  Department Head<span>*</span>{" "}
                </label>
                <select
                  onChange={(event) => {
                    setUpdateName(event.target.value);
                  }}
                  class="form-select datehis"
                  aria-label="Default select example"
                  value={updateName}
                >
                  <option selected>Choose Department Head</option>
                  {developers.map((developer) => {
                    return (
                      <option value={developer.name}>{developer.name}</option>
                    );
                  })}
                </select>
                <label>
                  Department Name<span>*</span>
                </label>
                <input
                  onChange={(event) => {
                    setUpdateDepartment(event.target.value);
                  }}
                  type="text"
                  className="form-control check"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={updateDepartment}
                />
              </form>
              <hr />

              <div class="form-edt-btn">
                <button
                  data-bs-dismiss="modal"
                  onClick={updateDepartments}
                  type="text"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to delete employes */}
      <div
        className="modal fade "
        id="delete-depart-Modal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div tabIndex={-1}>
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>Do you want do delete?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  data-bs-dismiss="modal"
                  onClick={deleteDepartment}
                  type="button"
                  className="btn btn-danger"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*modal for add department */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content mdldep">
            <div className="modal-header dephead">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Department
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                value={newName}
              />
            </div>
            <div className="modal-body mdlbdy">
              <form class="fomstl">
                <label> Department Head </label>
                <select
                  onChange={(event) => {
                    setNewName(event.target.value);
                  }}
                  class="form-select datehis"
                  aria-label="Default select example"
                  value={newName}
                >
                  <option selected>Choose Department Head</option>
                  {developers.map((developer) => {
                    return (
                      <option value={developer.id}>{developer.name}</option>
                    );
                  })}
                </select>
                <label>Department Name</label>
                <input
                  onChange={(event) => {
                    setNewDepartment(event.target.value);
                  }}
                  type="text"
                  className="form-control check"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={newDepartment}
                />
              </form>
              <hr />

              <div class="formbtn">
                <button
                  data-bs-dismiss="modal"
                  onClick={createDepartment}
                  type="text"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: "Department Name", field: "department" },
          {
            title: "Department Head",
            field: "name",
            render: (rowData) =>
              developers.find((item) => item.id === rowData.name)?.name,
          },
        ]}
        data={department}
        title=""
        actions={[
          {
            icon: () => (
              <button
                type="button"
                class="btn attbtn"
                data-bs-toggle="modal"
                data-bs-target="#edit-dep-Modal"
              >
                <i class="fa-solid fa-pencil"></i>
              </button>
            ),
            tooltip: "Edit data",
            onClick: (event, rowData) => {
              setSelectedDepartmentId(rowData.id);
              setUpdateName(rowData.name);
              setUpdateDepartment(rowData.department);
            },
          },
          {
            icon: () => (
              <div>
                {/* Button trigger modal */}
                <button
                  className="depbut"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Add Department
                </button>
              </div>
            ),
            isFreeAction: true,
            onClick: (event, rowData) => {},
          },
          {
            icon: () => (
              <button
                type="button"
                className="btn viewbtn"
                data-bs-toggle="modal"
                data-bs-target="#delete-depart-Modal"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
            tooltip: "Edit data",
            onClick: (event, rowData) => {
              setSelectedDepartmentId(rowData.id);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          toolbarButtonAlignment: "left",
        }}
        // localization={{
        //   pagination: {
        //     labelDisplayedRows: "{from}-{to} of {count}",
        //   },
        //   toolbar: {
        //     nRowsSelected: "{0} row(s) selected",
        //   },
        //   header: {
        //     actions: "",
        //   },
        //   body: {
        //     emptyDataSourceMessage: "No records to display",
        //     filterRow: {
        //       filterTooltip: "Filter",
        //     },
        //   },
        // }}
      />
    </>
  );
}

export default Department;
