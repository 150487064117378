import React,{useState} from 'react'

function Absenflt() {
  const [showTable, setShowTable] = useState(false);
  const handeltoggle = () => {
    setShowTable(!showTable);
  };
  return (
    <>
    <div className="container">
    <button class="filtercls" onClick={handeltoggle}>
      {showTable ? "Hide Filter" : "Show Filter"}
    </button>
    {showTable === true ? (
      <form>
        <div class="form-group nmg">
          <label class=" inpnmg" for="exampleInputEmail1">
          Id
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Id"
          />
        </div>
        <div class="form-group nmg">
        <label class=" inpnmg" for="exampleInputEmail1">
        Name
        </label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Name"
        />
      </div>
      <div class="form-group nmg">
      <label class=" inpnmg" for="exampleInputEmail1">
      From
      </label>
      <input
        type="date"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="From"
      />
    </div>
    <div class="form-group nmg">
    <label class=" inpnmg" for="exampleInputEmail1">
    To
    </label>
    <input
      type="date"
      class="form-control"
      id="exampleInputEmail1"
      aria-describedby="emailHelp"
      placeholder="To"
    />
  </div>
        <select
          class="form-select datehis"
          aria-label="Default select example"
        >
          <option selected>Department</option>
          <option value="1">React</option>
          <option value="2">Laravel</option>
          <option value="3">Flutter</option>
        </select>
        <div class="att-fil-btn">
          <button type="button">Filter</button>
        </div>
      </form>
    ) : null}
  </div>
    </>
  )
}

export default Absenflt
