import { collection, onSnapshot, query, where } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { db } from "../../../firebase";

function Empdetails() {
  const { id } = useParams();
  const [developers, setDevelopers] = useState([]);
  console.log(id);

  useEffect(() => {
    const fetchData = () => {
      const q = query(collection(db, "developers"), where("id", "==", id));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDevelopers(dev);
      });
    };
    fetchData();
  }, []);

  return (
    <>
      {developers.map((item, key) => {
        return (
          <div className="container empdetl">
            <div className="detl">
              <div className="texx">
                <h3>GG</h3>
                <h5>React Developer</h5>
              </div>
            </div>

            <hr />

            <div className="row pelrde">
              <h5>Personal Details</h5>
              <div className="col-6 detail">
                <p>Address: Tinkune,Kathmandu</p>
                <p>DOB: 2056-10-17</p>
              </div>
              <div className="col-6 detail">
                <p>Email: laxmibist009@gmail.com</p>
                <p>Phone No: 9867985649</p>
              </div>
            </div>

            <hr />

            <div className="row pelrde">
              <h5>Educational Details</h5>
              <div className="col-6 detail">
                <p>Elementary: Pashupati secondary school</p>
                <p>High School: Pashupati secondary school</p>
                <p>Collage: Pashupati secondary school</p>
              </div>
              <div className="col-6 detail">
                <p>Year Graduated: 2070</p>
                <p>Year Graduated: 2070</p>
                <p>Year Graduated: 2070</p>
              </div>
            </div>

            <hr />

            <div className="row jobdel">
              <h5>Job Details</h5>
              <div className="col-6 detail">
                <p>ID: ESP-101</p>
                <p>Supervisor: Sagar Subedi</p>
                <p>Work Location: Thinkune,Kathmandu</p>
                <p>Email Address: hishamarjan@creatudeveloper.com</p>
              </div>
              <div className="col-6 detail">
                <p>Work Experience: 5 years</p>
                <p>Phone Number: 099-987655</p>
                <p>Start Date: 2078-10-12</p>
                <p>Salary: 20,000</p>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
    </>
  );
}

export default Empdetails;
