import Image from "../Assets/signin.png";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from ".././firebase.js";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { UserContext } from "../Component/Homerouting/ContextApi"; // imported contextApi
import { useContext } from "react"; // imported contextApi

function Loginpage() {
  const { setUserRole, setUserData } = useContext(UserContext); // imported contextApi
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [email, setEmail] = useState("sagar@gmail.com");
  const [password, setPassword] = useState("password321");

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    const q = query(collection(db, "developers"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const dev = [];
    await querySnapshot.forEach((doc) => {
      dev.push({ ...doc.data(), id: doc.id });
    });

    if (dev.length === 0) {
      setLoading(false);
      return enqueueSnackbar("User Is Not Registered", { variant: "error" });
    } else if (dev[0].password !== password) {
      setLoading(false);
      return enqueueSnackbar("Password Is Incorrect", { variant: "error" });
    } else {
      setUserRole(dev[0].userRole);
      if (dev[0].userRole === "Admin") {
        setUserData(dev[0]);
        setLoading(false);
        navigate("/dashboard");
      } else {
        setUserData(dev[0]);
        setLoading(false);
        navigate("/userDashboard");
      }
    }
  };

  return (
    <>
      <div className="signin">
        <div className="container formbox">
          <div className="row">
            <div className="col-12 col-md-6 pb-5">
              <div className="form">
                <form>
                  <h1>Wel-Come</h1>
                  <label>Email:</label>
                  <input
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type="email"
                    required
                    value={email}
                  ></input>
                  <label>Password:</label>
                  <input
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    type="password"
                    required
                    value={password}
                  ></input>
                  {/* <a className="mb-3" href="#">
                    Forgot password?
                  </a> */}
                  {!loading ? (
                    <button
                      style={{ marginTop: "2rem" }}
                      className="btn btn-danger"
                      onClick={login}
                      type="button"
                    >
                      Login
                    </button>
                  ) : (
                    <button
                      style={{ marginTop: "2rem" }}
                      className="btn btn-danger"
                      type="button"
                    >
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  )}
                </form>
              </div>
            </div>

            <div className="col-6 col-md-6 ">
              <div className="form_image">
                <div className="imgDiv">
                  <img src={Image}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loginpage;
