import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useContext } from "react";
import { UserContext } from "../../Component/Homerouting/ContextApi";

function Leaveform() {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const usersCollectionRef = collection(db, "leave");
  const { enqueueSnackbar } = useSnackbar();
  const [newFullName, setNewFullName] = useState("");
  const [newTypeOfLeave, setNewTypeOfLeave] = useState("");
  const [newFrom, setNewFrom] = useState("");
  const [newTo, setNewTo] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newStatus, setNewStatus] = useState("Pending");
  const [loading, setLoading] = useState(false);

  const createLeave = async () => {
    if (newFullName === "") {
      return enqueueSnackbar("Full Name cannot be empty", { variant: "error" });
    } else if (newFrom === "") {
      return enqueueSnackbar("Start Date cannot be empty", {
        variant: "error",
      });
    } else if (newDescription === "") {
      return enqueueSnackbar("Reason cannot be empty", { variant: "error" });
    } else if (newTypeOfLeave === "") {
      return enqueueSnackbar("Type Of Leave cannot be empty", {
        variant: "error",
      });
    } else if (newTo === "" || newTo === newFrom) {
      return enqueueSnackbar("End Date cannot be same or empty", {
        variant: "error",
      });
    } else if (newFrom > newTo) {
      return enqueueSnackbar("Check the date Properly", { variant: "error" });
    } else {
      setLoading(true);
      await addDoc(usersCollectionRef, {
        name: newFullName,
        typeOfLeave: newTypeOfLeave,
        from: newFrom,
        to: newTo,
        decrp: newDescription,
        status: newStatus,
        userId: userData.id,
      })
        .then(() => {
          setNewFullName("");
          setNewFrom("");
          setNewTo("");
          setNewDescription("");
          setNewTypeOfLeave("");
        })
        .then(() =>
          enqueueSnackbar("Leave Request Submitted", { variant: "success" })
        );

      setLoading(false);
      navigate("/leavepage");
    }
  };

  return (
    <div className="container ">
      <div className="leave-from ">
        <h3>Leave Form</h3>
        <hr />
        <form className="row g-3 contant">
          <div className="col-10">
            <label
              for="inputPassword4"
              className="form-label"
              style={{ color: "white" }}
            >
              Fullname
            </label>
            <input
              type="text"
              className="form-control fominp"
              id="inputPassword4"
              value={newFullName}
              onChange={(event) => {
                setNewFullName(event.target.value);
              }}
            />
          </div>
          <div className="col-10">
            <label
              for="inputAddress2"
              className="form-label"
              style={{ color: "white" }}
            >
              From
            </label>
            <input
              value={newFrom}
              onChange={(event) => {
                setNewFrom(event.target.value);
              }}
              type="date"
              className="form-control fominp"
              id="inputAddress2"
            />
          </div>
          <div className="col-10">
            <label
              for="inputCity"
              className="form-label"
              style={{ color: "white" }}
            >
              To
            </label>
            <input
              value={newTo}
              onChange={(event) => {
                setNewTo(event.target.value);
              }}
              type="date"
              className="form-control fominp"
              id="inputCity"
            />
          </div>
          <div className="col-10">
            <label
              for="inputAddress"
              className="form-label"
              style={{ color: "white" }}
            >
              Reason for leave
            </label>
            <textarea
              className="form-control datehis"
              value={newDescription}
              onChange={(event) => {
                setNewDescription(event.target.value);
              }}
            />
          </div>
          <div className="col-10">
            <label
              for="inputAddress"
              className="form-label"
              style={{ color: "white" }}
            >
              status
            </label>
            <p
              onChange={(event) => {
                setNewStatus(event.target.value);
              }}
              type="text"
              className="form-control fominp"
              id="status"
            >
              {newStatus}
            </p>
          </div>
          <div className="col-10">
            <label
              for="exampleInputPassword1"
              className="form-label"
              style={{ color: "white" }}
            >
              Type Of Leave
            </label>
            <select
              onChange={(event) => {
                setNewTypeOfLeave(event.target.value);
              }}
              className="form-select datehis"
              aria-label="Default select example"
              value={newTypeOfLeave}
            >
              <option selected value="">
                Choose Type Of Leave
              </option>
              <option value="Annual Leave">Annual Leave</option>
              <option value="Sick Leave">Sick Leave</option>
            </select>
          </div>
        </form>
        <hr />
        <div className="butn">
          {!loading ? (
            <button onClick={createLeave} type="button">
              Submit
            </button>
          ) : (
            <button class="butn" type="button" disabled>
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          )}
          <Link to="/leavepage">
            <button type="button">Cancel</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Leaveform;
