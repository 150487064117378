import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Image1 from "../../Assets/man.png";
import Image2 from "../../Assets/woman.png";
import { UserContext } from "../Homerouting/ContextApi";

function Navbar() {
  const { userData } = useContext(UserContext);
  return (
    <div>
      <nav className="navbar navbar-expand-lg navcont sticky">
        <div className="container-fluid">
          <div className="left-div">
            <a className="navbar-brand" href="#">
              <span>
                <i className="fa-brands fa-42-group"></i>&nbsp;HRM
              </span>
            </a>
          </div>
          <div className="clearfix">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">≡</span>
            </button>
          </div>

          <div className="clearfix">
            <div
              className="collapse navbar-collapse text-end right-div"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <i className="fa-solid fa-bell"></i>
                </li>
                <div className="btn-group dropstart">
                  <button
                    type="button"
                    className=" btn-sm gg "
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="profile">
                      <a href="#">
                        {userData?.gender === "Male" ? (
                          <img src={Image1}></img>
                        ) : (
                          <img src={Image2}></img>
                        )}
                      </a>
                    </div>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <a className="dropdown-item" href="#">
                        {userData?.email}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link to="/" className="text-decoration-none">
                        <a className="dropdown-item" href="#">
                          Log Out
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
