import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navigation/Navbar";
import UserSidebar from "./Sidebar/userSidebar";
const SharedLayout2 = () => {
  return (
    <div>
      <div className="row row1">
        <div className="col-12">
          <Navbar />
        </div>
        <div className="col-12">
          <div className="Sideall">
            <UserSidebar />
          </div>
        </div>
        <div className="col-12 ">
          <div className="Sideall">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SharedLayout2;
