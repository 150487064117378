import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { UserContext } from "../../Component/Homerouting/ContextApi";

function Leavepage() {
  const { userData, setLeaveData } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [updateFullName, setUpdateFullName] = useState("");
  const [updateFrom, setUpdateFrom] = useState("");
  const [updateTo, setUpdateTo] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [selectedLeaveId, setSelectedLeaveId] = useState("");
  const [leave, setLeave] = useState([]);

  const updateLeave = async () => {
    if (updateFullName === "") {
      return enqueueSnackbar("Name cannot be empty", { variant: "error" });
    } else if (updateFrom === "") {
      return enqueueSnackbar("Email cannot be empty", { variant: "error" });
    } else if (updateTo === "") {
      return enqueueSnackbar("Email cannot be empty", { variant: "error" });
    } else if (updateDescription === "") {
      return enqueueSnackbar("Gender cannot be empty", { variant: "error" });
    } else {
      const leaveDoc = doc(db, "leave", selectedLeaveId);
      await updateDoc(leaveDoc, {
        name: updateFullName,
        from: updateFrom,
        to: updateTo,
        decrp: updateDescription,
      }).then(() =>
        enqueueSnackbar("Leave Request Updated ", { variant: "success" })
      );
    }
  };

  const deleteLeave = async () => {
    const leaveDoc = doc(db, "leave", selectedLeaveId);
    await deleteDoc(leaveDoc).then(() =>
      enqueueSnackbar("Leave Request Deleted Successfully", {
        variant: "error",
      })
    );
  };

  useEffect(() => {
    const fetchData = () => {
      if (userData) {
        const q = query(
          collection(db, "leave"),
          where("userId", "==", userData.id)
        );
        onSnapshot(q, (querySnapshot) => {
          const dev = [];
          querySnapshot.forEach((doc) => {
            dev.push({ ...doc.data(), id: doc.id });
          });
          setLeave(dev);
          setLeaveData(dev);
        });
      }
    };
    fetchData();
  }, [userData]);

  return (
    <>
      <div style={{ marginLeft: "16rem" }} className="container">
        {/* to edit leave request */}
        <div
          className="modal fade"
          id="edit-emp-Modal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header add-emp-tit">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Leave Request
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1 "
                      className="form-label chklab"
                    >
                      Name<span>*</span>
                    </label>
                    <input
                      onChange={(event) => {
                        setUpdateFullName(event.target.value);
                      }}
                      type="text"
                      className="form-control check"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={updateFullName}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="exampleInputPassword1"
                      className="form-label chklab"
                    >
                      From<span>*</span>
                    </label>
                    <input
                      onChange={(event) => {
                        setUpdateFrom(event.target.value);
                      }}
                      type="date"
                      className="form-control check"
                      id="exampleInputPassword1"
                      value={updateFrom}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputPassword1"
                      className="form-label chklab"
                    >
                      To<span>*</span>
                    </label>
                    <input
                      onChange={(event) => {
                        setUpdateTo(event.target.value);
                      }}
                      type="date"
                      className="form-control check"
                      id="exampleInputPassword1"
                      value={updateTo}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputPassword1"
                      className="form-label chklab"
                    >
                      Reason To Leave<span>*</span>
                    </label>
                    <textarea
                      onChange={(event) => {
                        setUpdateDescription(event.target.value);
                      }}
                      type="text"
                      className="form-control check"
                      id="exampleInputPassword1"
                      value={updateDescription}
                    />
                  </div>
                </form>
              </div>
              <hr />
              <div className="attsavchg">
                <button
                  data-bs-dismiss="modal"
                  onClick={updateLeave}
                  type="button"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* to delete leave request */}

        <div
          className="modal fade "
          id="delete-emp-Modal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div tabIndex={-1}>
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Delete</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <p>Do you want do delete?</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    onClick={deleteLeave}
                    type="button"
                    className="btn btn-danger"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MaterialTable
          title={""}
          columns={[
            { title: "Name", field: "name" },
            { title: "From", field: "from" },
            { title: "To", field: "to" },
            { title: "Description", field: "decrp" },
            {
              title: "Status",
              field: "status",
              render: (data) => {
                if (data.status === "Accepted") {
                  return <span class="badge bg-success">{data.status}</span>;
                } else if (data.status === "Rejected") {
                  return <span class="badge bg-danger">{data.status}</span>;
                } else {
                  return <span class="badge bg-warning">{data.status}</span>;
                }
              },
            },
            { title: "Message", field: "RejectedMessage" },
          ]}
          data={leave}
          actions={[
            (Data) => {
              return Data.status === "Accepted" || Data.status === "Rejected"
                ? null
                : {
                    icon: () => (
                      <button
                        type="button"
                        className="btn attbtn"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-emp-Modal"
                        disabled={false}
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </button>
                    ),
                    tooltip: "Edit data",
                    onClick: (event, rowData) => {
                      setSelectedLeaveId(rowData.id);
                      setUpdateFullName(rowData.name);
                      setUpdateFrom(rowData.from);
                      setUpdateTo(rowData.to);
                      setUpdateDescription(rowData.decrp);
                    },
                  };
            },
            {
              icon: () => (
                <button type="button" className="leacebtn">
                  Apply Leave
                </button>
              ),
              tooltip: "See more",
              isFreeAction: true,
              onClick: (event, rowData) => {
                navigate("/leaveform");
              },
            },
            {
              icon: () => (
                <button
                  type="button"
                  className="btn viewbtn"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-emp-Modal"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              ),
              tooltip: "Edit data",
              onClick: (event, rowData) => {
                setSelectedLeaveId(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            toolbarButtonAlignment: "left",
          }}
        />
      </div>
    </>
  );
}

export default Leavepage;
