import React, { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [userRole, setUserRole] = useState("User");
  const [userData, setUserData] = useState(null);
  const [leaveData, setLeaveData] = useState("");

  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem("userInfo"));
    let temp1 = JSON.parse(localStorage.getItem("userRole"));
    setUserData(temp);
    setUserRole(temp1);
  }, []);

  useEffect(() => {
    if (userData && userRole) {
      localStorage.setItem("userInfo", JSON.stringify(userData));
      localStorage.setItem("userRole", JSON.stringify(userRole));
    }
  }, [userData, userRole]);

  return (
    <UserContext.Provider
      value={{
        userRole,
        setUserRole,
        userData,
        setUserData,
        leaveData,
        setLeaveData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
