import React from "react";
import MaterialTable from "material-table";
import Attdnsflt from "./Filterlist/Attdnsflt";
import moment from "moment/moment";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { db } from "../../firebase";
import { useEffect } from "react";

function Attendans() {
  const { enqueueSnackbar } = useSnackbar();
  const [developers, setDevelopers] = useState([]);
  const [department, setDepartment] = useState([]);

  const [selectedAttendanceId, setSelectedAttendanceId] = useState("");
  const [updateCheckIn, setUpdateCheckIn] = useState("");
  const [updateCheckOut, setUpdateCheckOut] = useState("");
  const [newName, setNewName] = useState("");
  const [newCheckIn, setNewCheckIn] = useState("");
  const [newCheckOut, setNewCheckOut] = useState("");
  const [attendance, setAttendance] = useState([]);
  const usersCollectionRef = collection(db, "Attendance");

  const createAttendance = async () => {
    if (newName === "") {
      return enqueueSnackbar("Name cannot be empty", { variant: "error" });
    } else {
      await addDoc(usersCollectionRef, {
        Name: newName,
        CheckIn: newCheckIn,
        CheckOut: newCheckOut,
      })
        .then(() => {
          setNewName("");
          setNewCheckIn("");
          setNewCheckOut("");
        })
        .then(() =>
          enqueueSnackbar("Attendance Added Successfully", {
            variant: "success",
          })
        );
    }
  };
  const updateAttendance = async () => {
    if (updateCheckIn === "") {
      return enqueueSnackbar("CheckIn cannot be empty", { variant: "error" });
    } else if (updateCheckOut === "") {
      return enqueueSnackbar("CheckOut cannot be empty", { variant: "error" });
    } else {
      const AttendanceDoc = doc(db, "Attendance", selectedAttendanceId);
      await updateDoc(AttendanceDoc, {
        CheckIn: updateCheckIn,
        CheckOut: updateCheckOut,
      }).then(() =>
        enqueueSnackbar("Attendance Updated Successfully", {
          variant: "success",
        })
      );
    }
  };

  useEffect(() => {
    const getAttendance = async () => {
      const q = query(collection(db, "Attendance"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setAttendance(dev);
      });
    };
    getAttendance();

    const getDevelopers = async () => {
      const q = query(collection(db, "developers"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDevelopers(dev);
      });
    };
    getDevelopers();

    const getDepartment = async () => {
      const q = query(collection(db, "department"));
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
        });
        setDepartment(dev);
      });
    };
    getDepartment();
  }, []);

  function getDifferenceInDays(date1, date2) {
    let startTime = new Date(date1).getTime();
    let endTime = new Date(date2).getTime();
    let time = `${(((endTime - startTime) / 3600000) * 60).toFixed(2)}`;
    var Hours = Math.floor(time / 60);
    var minutes = time % 60;
    return Hours + " hour and " + minutes + " minutes.";
  }

  return (
    <>
      <Attdnsflt />
      {/* Modal for employes add form */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mdlnewemp">
          <div className="modal-content ">
            <div className="modal-header emphed">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Attendance
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1 "
                    className="form-label chklab"
                  >
                    Name<span>*</span>
                  </label>

                  <select
                    onChange={(event) => {
                      setNewName(event.target.value);
                    }}
                    class="form-select datehis"
                    aria-label="Default select example"
                    value={newName}
                  >
                    <option selected>Choose Name</option>
                    {developers.map((developer) => {
                      return (
                        <option value={developer.id}>{developer.name}</option>
                      );
                    })}
                  </select>
                </div>

                <div className="mb-3">
                  <label for="startDateAndTime" className="form-label chklab">
                    Check-In<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewCheckIn(event.target.value);
                    }}
                    type="datetime-local"
                    className="form-control check"
                    id="startDateAndTime"
                    value={newCheckIn}
                  />
                </div>
                <div className="mb-3">
                  <label for="endDateAndTime" className="form-label chklab">
                    Check-Out<span>*</span>
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setNewCheckOut(event.target.value);
                    }}
                    type="datetime-local"
                    className="form-control check"
                    id="endDateAndTime"
                    value={newCheckOut}
                  />
                </div>
              </form>
              <hr />
              <div className="empbtn">
                <button
                  onClick={createAttendance}
                  data-bs-dismiss="modal"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal for editing Attendance */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header att-tit">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3">
                  <label for="exampleInputEmail1 " class="form-label chklab">
                    Check-In
                  </label>
                  <input
                    type="datetime-local"
                    class="form-control check"
                    id="exampleInputEmail1"
                    value={updateCheckIn}
                    onChange={(event) => {
                      setUpdateCheckIn(event.target.value);
                    }}
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label chklab">
                    Check-Out
                  </label>
                  <input
                    type="datetime-local"
                    class="form-control check"
                    id="exampleInputPassword1"
                    value={updateCheckOut}
                    onChange={(event) => {
                      setUpdateCheckOut(event.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <hr />
            <div class="attsavchg">
              <button
                data-bs-dismiss="modal"
                onClick={updateAttendance}
                type="button"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableContainer">
        {" "}
        <MaterialTable
          columns={[
            { title: "ID", field: "id" },

            {
              title: "Name",
              field: "Name",
              render: (rowData) =>
                developers.find((item) => item.id === rowData.Name)?.name,
            },
            {
              title: "Department",
              field: "department",
              render: (rowData) =>
                department.find(
                  (dep) =>
                    dep.id ===
                    developers.find((item) => item.id === rowData.Name)
                      ?.department
                )?.department,
            },
            {
              title: "Check-in",
              field: "CheckIn",
              render: (data) => {
                if (data.CheckIn !== "") {
                  return moment(data.CheckIn).format("MMM Do YYYY, h:mm A");
                } else {
                  return null;
                }
              },
            },
            {
              title: "Check-out",
              field: "CheckOut",
              render: (data) => {
                if (data.CheckOut !== "") {
                  return moment(data.CheckOut).format("MMM Do YYYY, h:mm A");
                } else {
                  return null;
                }
              },
            },
            {
              title: "Total Hour",
              field: "TotalHour",
              render: (data) =>
                getDifferenceInDays(data.CheckIn, data.CheckOut),
            },
          ]}
          data={attendance}
          title=""
          actions={[
            {
              icon: () => (
                <button
                  type="button"
                  class="btn attbtn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i class="fa-solid fa-pencil"></i>
                </button>
              ),
              onClick: (event, rowData) => {
                setSelectedAttendanceId(rowData.id);
                setUpdateCheckIn(rowData.CheckIn);
                setUpdateCheckOut(rowData.CheckOut);
              },
            },
            {
              icon: () => (
                <div>
                  {/* Button trigger modal */}
                  <button
                    className="newempbtn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                  >
                    Add Attendance
                  </button>
                </div>
              ),
              isFreeAction: true,
              onClick: (event, rowData) => {},
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            toolbarButtonAlignment: "left",
          }}
          // localization={{
          //   pagination: {
          //     labelDisplayedRows: "{from}-{to} of {count}",
          //   },
          //   toolbar: {
          //     nRowsSelected: "{0} row(s) selected",
          //   },
          //   header: {
          //     actions: "",
          //   },
          //   body: {
          //     emptyDataSourceMessage: "No records to display",
          //     filterRow: {
          //       filterTooltip: "Filter",
          //     },
          //   },
          // }}
        />
      </div>
    </>
  );
}

export default Attendans;
