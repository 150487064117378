import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Levreqflt from "./Filterlist/Levreqflt";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useSnackbar } from "notistack";

function Leavereq() {
  const [leaveRequest, setLeaveRequest] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedLeaveId, setSelectedLeaveId] = useState("");
  const [rejected, setRejected] = useState("");

  const Rejected = (e, status1) => {
    e.preventDefault();
    const leaveDoc = doc(db, "leave", selectedLeaveId);
    updateDoc(leaveDoc, { status: status1, RejectedMessage: rejected }).then(
      () => enqueueSnackbar("Leave Request Rejected", { variant: "error" })
    );
  };

  const Accepted = (e, status, id) => {
    e.preventDefault();
    const departmentDoc = doc(db, "leave", id);
    updateDoc(departmentDoc, { status: status }).then(() =>
      enqueueSnackbar("Leave Request Accepted", { variant: "success" })
    );
  };

  useEffect(() => {
    const getLeaveRequest = async () => {
      const q = query(
        collection(db, "leave"),
        where("status", "==", "Pending")
      );
      onSnapshot(q, (querySnapshot) => {
        const dev = [];
        querySnapshot.forEach((doc) => {
          dev.push({ ...doc.data(), id: doc.id });
          console.log(dev);
        });
        setLeaveRequest(dev);
      });
    };
    getLeaveRequest();
  }, []);

  return (
    <>
      <Levreqflt />
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header leave-hed">
              <h5>Rejection Reason </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body rejtext">
              <textarea
                onChange={(event) => {
                  setRejected(event.target.value);
                }}
              ></textarea>
            </div>
            <div className="btn">
              <button
                data-bs-dismiss="modal"
                onClick={(event) => Rejected(event, "Rejected")}
                className="btn-danger"
                type="button"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <MaterialTable
        columns={[
          { title: "ID", field: "id" },
          { title: "Description", field: "decrp" },
          { title: "Name", field: "name" },
          { title: "From", field: "from" },
          { title: "To", field: "to" },
          {
            title: "Status",
            field: "status",
            render: (data) => {
              if (data.status === "Accepted") {
                return <span class="badge bg-success">{data.status}</span>;
              } else if (data.status === "Rejected") {
                return <span class="badge bg-danger">{data.status}</span>;
              } else {
                return <span class="badge bg-warning">{data.status}</span>;
              }
            },
          },
        ]}
        data={leaveRequest}
        title=" "
        actions={[
          {
            icon: () => <button class="btn acc-btn">Accept</button>,
            onClick: (event, rowData) => {
              Accepted(event, "Accepted", rowData.id);
            },
          },
          {
            icon: () => (
              <button
                type="button"
                class="btn rej-btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Reject
              </button>
            ),
            onClick: (event, rowData) => {
              setSelectedLeaveId(rowData.id);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        // localization={{
        //   pagination: {
        //     labelDisplayedRows: "{from}-{to} of {count}",
        //   },
        //   toolbar: {
        //     nRowsSelected: "{0} row(s) selected",
        //   },
        //   header: {
        //     actions: "",
        //   },
        //   body: {
        //     emptyDataSourceMessage: "No records to display",
        //     filterRow: {
        //       filterTooltip: "Filter",
        //     },
        //   },
        // }}
      />
    </>
  );
}

export default Leavereq;
