import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAxl1LFkAb5FdzYn9ftESdvceYv8q6cR0Q",
  authDomain: "project-management-9aaf6.firebaseapp.com",
  projectId: "project-management-9aaf6",
  storageBucket: "project-management-9aaf6.appspot.com",
  messagingSenderId: "844494836236",
  appId: "1:844494836236:web:adacc94cb0f9a4eab89429",
  measurementId: "G-Q6Q6SNPXWJ",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);
