import React, { useState } from "react";
import MaterialTable from "material-table";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Update() {
  const navigate = useNavigate();
  const [data, setstate] = useState([
    {
      title: "Employes",
      text1: "[200]",
      link: "/Employes",
    },
    {
      title: "Leave Request",
      text1: "[2]",
      link: "/Leavereq",
    },
    {
      title: "Absentees",
      text1: "[4]",
      link: "/Absentees",
    },
    {
      title: "New Hires",
      text1: "[10]",
      link: "/Newhire",
    },
  ]);

  const [value, setvalue] = useState();
  return (
    < >
      <div className=" updt-cont">
        <div className="row updatehrz">
          {data.map((val, ids) => {
            return (
              <div className="col-3">
                <Link to={val.link} className="link-update">
                  <div className="card updatecrd">
                    <div className="card-bdy updt-bdy">
                      <p>{val.title}</p>
                      <h4>{val.text1}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Update;
