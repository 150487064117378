import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";

const UserSidebar = () => {
  return (
    <>
      <div className="sidebar">
        <div className="navlist">
          <ul className="item">
            <li>
              <Link to="/userDashboard">
                <div>
                  <i className="fa-solid fa-house-chimney"></i>
                </div>
                Dashboard
              </Link>
            </li>

            <li>
              <Link to="/attendance">
                <div>
                  <i class="fa-solid fa-calendar-check"></i>
                </div>
                Attendance
              </Link>
            </li>

            <li>
              <Link to="/leavePage">
                <div>
                  <i class="fa-solid fa-calendar-days"></i>
                </div>
                Leave <br />
                Request
              </Link>
            </li>

            <li>
              <Link to="/timeTracker">
                <div>
                  <i class="fa-solid fa-clock"></i>
                </div>
                Time
                <br /> Tracker
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserSidebar;
