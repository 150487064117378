import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "./Navigation/Navbar";
const SharedLayout = () => {
  return (
    <div>
      <div className="row row1">
        <div className="col-12">
          <Navbar />
        </div>
        <div className="col-1">
          <div className="Sideall">
            <Sidebar />
          </div>
        </div>
        <div class="col-11 homeall">
          <div class="Sideall">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SharedLayout;
